/**
 * Custom branding for the background
 */
.slds-brand-band.slds-brand-band_large:before {
    height: 100vh;
}

.slds-brand-band:before {
    background-repeat: repeat;
}

.slds-brand-band.slds-brand-band_large:after {
    display: none;
}

.slds-modal__content {
    font-size: .95rem;
}

.slds-modal__footer {
    font-size: .95rem;
}

.slds-backdrop {
    opacity: 0.2;
}

/**
 * Use css to hide the datepicker input since there's no way to do it via the SLDS React component
 */
.viewCalendarWeekHeader .viewCalendarDatePicker > div:first-of-type input {
    visibility: hidden;
    width: 0;
}

.viewCalendarWeekHeader .viewCalendarDatePicker button {
    right: 0.5rem !important;
}

/**
 * Manually style the button as disabled when loading
 */
.viewCalendarWeekHeader .viewCalendarDatePicker.loading button {
    color: #dddbda;
    cursor: default;
    right: 0.5rem !important;
}

.viewCalendarWeekHeader .controlContainer {
    align-items: center;
}

.viewCalendarWeek {
    height: 60vh;
}

/**
 * Mobile support for viewCalendarWeek: Display the available slots in a single column, instead of 5 days/columns.
 */
@media (max-width: 799px) {
    .mobile_flex_direction_vertical {
        flex-direction: column;
        -ms-flex-direction: column;
    }

    .mobile_flex_direction_vertical .slds-size_1-of-2 {
        width: 100%;
    }
    .mobile_flex_direction_vertical .slds-size_1-of-5 {
        width: 100%;
    }
    .mobile_flex_direction_vertical .slds-size_1-of-7 {
        width: 100%;
    }

    .mobile_only_visibile {
        display: block;
    }
    .mobile_only_hide {
        display: none;
    }
}

@media (min-width: 800px) {
    .mobile_only_visible {
        display: none;
    }
}

@media (max-height:500px) {
    .smallheight_hide {
        display: none;
    }
}
